import { getResults } from '../ajax/results';

import flatpickr from 'flatpickr';

export default class Bets {
  constructor() {
    this.blocks = document.querySelectorAll('.block-bets');

    if (0 < this.blocks.length) {
      this.blocks.forEach(block => new Block(block));
    }
  }
}

class Block {
  /**
   * @param {HTMLElement} block 
   */
  constructor(blockElement) {
    this.blockElement = blockElement;
    this.form = this.blockElement.querySelector('form');
    this.filters = new Filters(this);
    this.results = new Results(this);  
  }
}

class Filters {
  #keyword = null;

  #game = null;

  #team = null;

  #leagure = null;

  #date = null;

  /**
   * 
   * @param {Block} block 
   */
  constructor(block) {
    this.block = block;
    this.datepicker = this.datepicker();

    this.inputs = {
      keyword: this.block.form.querySelector('input[name="keyword"]'),
      game: this.block.form.querySelector('input[name^="game"]'),
      date: this.block.form.querySelector('input[name="date"]'),
    };

    this.dateButtons = block.form.querySelectorAll('.filter-dates-selectable__button');

    for (const [key, value] of Object.entries(this.inputs)) {
      if (true !== value instanceof HTMLInputElement) {
        console.error(`Could not find input of name "${key}".`);
      }
    }

    this.eventListeners();
		this.addPastDateClasses();
  }

  eventListeners() {
    this.dateButtons.forEach(button => button.addEventListener('click', (event) => {
      this.handleDateButton(event);
      this.block.results.load();
    }));

    this.inputs.keyword.addEventListener('keyup', (event) => this.handleSearch());

    window.addEventListener('load', () => this.block.results.load());
    this.block.form.addEventListener('change', () => this.block.results.load() );
  }

	addPastDateClasses() {
		this.dateButtons.forEach((button) => {
			const buttonDate = new Date(parseInt(button.dataset.date));

			const todayDate = new Date();
			
			if (Math.floor(todayDate.getTime() / 1000) > buttonDate.getTime()) {
				button.classList.add('--past');
			}
		});
	}

  loadSearchResults = () => {
    this.block.results.load();
  }

  debounce(fn, d) {
    let timer;
    return function () {
    let context = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
            fn.apply(context, args);
        }, d)
    }
  }

  handleSearch = this.debounce(this.loadSearchResults, 300);

  datepicker() {
    return flatpickr(this.block.form.querySelector('.block-bets__datepicker'), {
      onValueUpdate: this.handleDatepickerChange,
    });
  }

  handleDateButton(event) {
    const date = new Date(event.target.dataset.date * 1000);
		const wasChecked = event.target.classList.contains('--checked');
    this.dateButtons.forEach(button => button.classList.remove('--checked'));

		if (! wasChecked) {
			event.target.classList.add('--checked');
			this.datepicker.setDate(date);
		} else {
			this.datepicker.setDate(null);
		}
  }

  handleDatepickerChange(selectedDates) {
    
  }

  set keyword(value) {
    this.keyword.value = value;
  }

  get keyword() {
    return this.keyword.value;
  }
}

class Results {
  /**
   * @param {HTMLElement} block 
   */
   constructor(block) {
    this.block = block;
    this.resultsElement = this.block.blockElement.querySelector('.block-bets__results');
  }

  async retrieveResults() {
    return await getResults(this.block.form)
  }

  load() {
    this.ajaxStart();

    this.retrieveResults().then((response) => {
      
      if (response.length > 0) {
        this.block.blockElement.querySelector('.block-bets__nodata-notice').classList.add('-hide');
      } else {
        this.block.blockElement.querySelector('.block-bets__nodata-notice').classList.remove('-hide');
      }

      response.forEach((result) => {
        this.addList(result);
      });

      this.ajaxFinished();
    });
  }

  ajaxStart() {
    this.resultsElement.querySelector('.loader').classList.remove('-hide');

    this.block.form.querySelectorAll('input').forEach((el) => {
      el.readonly = true;
    });

    this.resultsElement.querySelectorAll('.block-bets__list').forEach((el) => {
      if (el.classList.contains('block-bets__list--template')) {
        return;
      }

      el.remove();
    });
  }

  ajaxFinished() {
    this.block.form.querySelectorAll('input').forEach((el) => {
      el.readonly = false;
    });

    this.resultsElement.querySelectorAll('.loader').forEach((el) => {
      el.classList.add('-hide');
    });

    this.eventListeners();
  }

  eventListeners() {
    this.resultsElement.querySelectorAll('.block-bets__button--spela').forEach(button => button.addEventListener('mouseover', (event) => {
      event.preventDefault();

      const banners = this.block.blockElement.querySelector('.block-bets__banners');

      button.appendChild(banners);
      banners.classList.add('block-bets__banners--visible');

      // banners.closest('block-bets__row').

      button.addEventListener('mouseout', (event) => {
        banners.classList.remove('block-bets__banners--visible');
      });
    }));
  }

  addList(result) {
    const listElement = this.block.blockElement.querySelector('.block-bets__list--template').cloneNode(true);
    
    if (null === listElement) {
      return;
    }

    listElement.classList.remove('block-bets__list--template');
    listElement.querySelector('.block-bets__row--template').remove();

    const listTime = new Date(result.events[0].time)
    listElement.querySelector('.block-bets__results-subtitle > span').innerHTML = result.game.display_name + ' / ' + (listTime.getDate() < 10 ? ('0' + listTime.getDate()) : listTime.getDate()) + ' ' + listTime.toLocaleString('en-EN', { month: 'long' });
    
    if (null !== result.game.image &&  null !== result.game.image.url) {
      listElement.querySelector('.block-bets__results-subtitle > img').src = result.game.image.url;
    }

    listElement.querySelector('.block-bets__results-title').innerHTML = result.name;

    result.events.forEach((event) => {
      const rowElement = this.block.blockElement.querySelector('.block-bets__row--template').cloneNode(true);

      rowElement.classList.remove('block-bets__row--template')

      const timeElement = rowElement.querySelector('.block-bets__time');

			const score = event.score !== null ? event.score.split('-') : [];

      if ('inplay' === event.status) {
        timeElement.classList.add('-live');
        timeElement.innerHTML = 'LIVE';
      } else if ('ended' === event.status) {
        timeElement.innerHTML = 'FINISHED';
				rowElement.classList.add('block-bets__row--finished')
				rowElement.querySelector('.block-bets__button--spela').style.display = 'none';
				if (score.length === 2) {
					rowElement.querySelector('.block-bets__team-score--first').innerHTML = score[0];
					rowElement.querySelector('.block-bets__team-score--second').innerHTML = score[1];
				}
				
      } else if ('upcoming') {
        const eventTime = new Date(event.time)
        rowElement.querySelector('.block-bets__time').innerHTML = (eventTime.getHours() < 10 ? ('0' + eventTime.getHours()) : eventTime.getHours()) + ':' + (eventTime.getMinutes() < 10 ? ('0' + eventTime.getMinutes()) : eventTime.getMinutes());
      }

      const teams = rowElement.querySelectorAll('.block-bets__team');

			if ('ended' === event.status && score.length === 2) {
				if (score[0] > score[1]) {
					teams[0].querySelector('.block-bets__team-name').classList.add('block-bets__team-name--won');
				} else if (score[0] < score[1]) {
					teams[1].querySelector('.block-bets__team-name').classList.add('block-bets__team-name--won');
				}
			}

      teams[0].querySelector('.block-bets__team-name').innerHTML = event.home_team.name;

      if (event.home_team.image_url.length > 0) {
        teams[0].querySelector('.block-bets__team-logo').src = event.home_team.image_url;
      }
      
      teams[1].querySelector('.block-bets__team-name').innerHTML = event.away_team.name;
      if ( event.away_team.image_url.length > 0) {
        teams[1].querySelector('.block-bets__team-logo').src = event.away_team.image_url;
      }

			rowElement.dataset.id = event.id;
      
      listElement.appendChild(rowElement);
    });

    this.resultsElement.appendChild(listElement);

    return listElement;
  }
}