/**
 * @param {HTMLFormElement} form 
 */
export const getResults = async function(form) {
  if (true !== form instanceof HTMLFormElement) {
    console.error('Function argument must be instance of HTMLFormElement');

    return;
  }

  try {
    const formData = new FormData(form);
    formData.append('action', 'esportlandet_get_results');
    formData.append('nonce', form.querySelector('#esportlandet_get_events_nonce').value)

    const response = await fetch(form.action, {
      method: "POST",
      body: formData
    });

    if (!response.ok) {
      throw new Error(`[${response.status}] ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error(error);
  }
}
